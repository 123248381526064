import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/(card-layout)/code-snippets/create": [~7,[2,3]],
		"/(app)/(card-layout)/code-snippets/[id]": [~5,[2,3]],
		"/(app)/(card-layout)/code-snippets/[id]/edit": [~6,[2,3]],
		"/maintenance": [~9],
		"/(app)/(card-layout)/profile": [~8,[2,3]],
		"/sign-in": [~10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';